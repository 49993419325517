body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  background: #F5F5F5;
}
html , body , #root , .App {
  height: 100%;
}
a {
  text-decoration: none;
  color: inherit;
}
.Main {
  flex: 1 0 auto;
  margin-bottom: -90px;
}

button {
  border: none;
  outline: none;
}
.App {
  display: flex;
  flex-direction: column;
}
