.Cutter {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  flex-direction: column;
  canvas {
    border: 2px dashed rgb(38, 58, 76);
    border-radius: 50%;
  }
}
.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.inputfileCustom {
  max-width: 300px;
  transition: 0.2s;

  img{
    width: 100%;
  }
  h2 {
    text-align: center;
    text-transform: uppercase;
    font-size: 20px;
    color: #203A4E;
    margin: 0;
    padding: 16px 0px;
    padding-bottom: 0;
    transform: translateY(-5px);
  }
}
label {
  margin-top: 20px;
  cursor: pointer !important;
  height: 50px;
  background: #203A4E;
  color: #F5F5F5;
  border-radius: 5px;
  font-size: 0.8rem;
  text-transform: uppercase;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s;
  @media (hover) , (min-width:0\0) , (min--moz-device-pixel-ratio:0) {
    &:hover {
      opacity: 0.6;
      cursor: pointer !important;
      transition: 0.2s;
    }
  }
}

.buttons {
  width: 100%;
  display: flex;
  button {
    width: 100%;
    height: 50px;
    background: #203A4E;
    color: #F5F5F5;
    border-radius: 5px;
    font-size: 0.8rem;
    text-transform: uppercase;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.2s;
    margin-top: 40px;
    @media (hover) , (min-width:0\0) , (min--moz-device-pixel-ratio:0) {
      &:hover {
        opacity: 0.8;
        cursor: pointer;
        transition: 0.2s;
      }
    }
  }
  .saveButton {
    margin-left: 10px;
  }
  .resetButton {
    margin-right: 10px;
  }
}
.rangeWrapper {
  display: flex;
  align-items: center;
  margin-top: 40px;
  .scopeMinus , .scopePlus {
    height: 28px;
    min-width: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #203A4E;
    color: #F5F5F5;
    border-radius: 50%;
    font-weight: bold;
    transition: 0.2s;
    -moz-user-select: none;
    -khtml-user-select: none;
    user-select: none;
    @media (hover) , (min-width:0\0) , (min--moz-device-pixel-ratio:0) {
      &:hover {
        opacity: 0.8;
        cursor: pointer;
        transition: 0.2s;
      }
    }
  }
  .scopeMinus {
    margin-right: 20px;
  }
  .scopePlus {
    margin-left: 20px;
  }
}

.errorMessage{
  color: #c34040;
  text-align: center;
}
.results {
  font-size: 20px;
  color: #203A4E;
  max-width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  img{
    max-width: 100%;
  }
  p {
    word-break: break-all;
  }
  .resetButtonResult {
    width: 300px;
    height: 50px;
    background: #203A4E;
    color: #F5F5F5;
    border-radius: 5px;
    font-size: 0.8rem;
    text-transform: uppercase;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.2s;
    margin-top: 40px;
    @media (hover) , (min-width:0\0) , (min--moz-device-pixel-ratio:0) {
      &:hover {
        opacity: 0.8;
        cursor: pointer;
        transition: 0.2s;
      }
    }
  }
}

